export default {
  init() {
    $('.navbar-toggler').on('click', function() {
      var click = $(this).data('clicks');
      if (click) {
        $(this).removeClass('open-toggle');
        $(this).addClass('close-toggle');
        $('.nav-primary').removeClass('open').addClass('close');
        $('html').removeClass('freeze');
      } else {
        $(this).addClass('open-toggle').removeClass('close-toggle');
        $('.nav-primary').removeClass('closed').removeClass('close').addClass('open');
        $('html').addClass('freeze');
      }
      $(this).data('clicks', !click);
    });

    $('.audio-player').each(function() {
      var player = $(this);

      $('.play', player).click(function() {
        $(this).toggleClass('hide');
        $('.pause', player).toggleClass('hide');
      });

      $('.pause', player).click(function() {
        $(this).toggleClass('hide');
        $('.play', player).toggleClass('hide');
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
